import React from "react"
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { Grid, Margin, PageWrapper } from '@components/atoms'
import { GridItem } from '@components/molecules'
import { TransitionMask } from '@components/organisms'
import { Title } from '@components/molecules/GridItem'
import { useHash } from '@utils'
import { GridItemCaptionProps, GridItemImageProps, GridItemVideoProps } from '@types'


const TagPage = ({ data: { allSanityPost: { nodes }}}: TagPageProps) => {
  
  const [tag] = useHash()

  const [parsedNodes, setParsedNodes] = React.useState<any[]>([])

  React.useEffect(() => {
    const parsed = nodes.filter(node => 
      node.tags.filter(
        t => t.tag.toLowerCase() === tag.replace('#', '').replace('-', ' ')
      ).length > 0
    )
    .map(node => ({
      ...node, 
      item: node.gridItems.filter(item => item.gridItemTypeSwitch?.gridItemType === 'image')[0],
    }))
    setParsedNodes(parsed)
  }, [tag, nodes])
  
  const parsedTitle = 'Franklyn: ' + tag
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },  
  ]
  
  const layout = {
    layout: {
      columns: 6,
      mobileColumns: 3,
    }
  }
  
  return (
    <PageWrapper>
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>
      <Margin>
        <Grid>
          <TagName>{tag}</TagName>
          {parsedNodes.map((node, index) => (
            <GridItem {...node.item} layout={layout} post={node} key={node.slug.current} >
              <Title>
                <h2 className="linkHighlight" >{node.title}</h2>
              </Title>
            </GridItem>
          ))}
        </Grid>
      </Margin>
      <TransitionMask />
    </PageWrapper>
  )
}


const TagName = styled.h1`
  position: relative;
  color: rgb(159, 159, 159);
  grid-column: 1 / span 6;
  @media only screen and (min-width: 744px) {
    grid-column: 1 / span 12;
  }
  text-align: right;
  font-family: 'HCo Sentinel SSm', serif;
  font-weight: 300;
  font-size: 4.8rem;
  letter-spacing: 0;
  line-height: 1em;
  @media only screen and (min-width: 744px) {
    font-size: 6.6rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 7.2rem;
  }
`


interface TagPageProps {
  data: {
    allSanityPost: {
      nodes: [{
        title: string
        slug: {
          current: string
        }
        gridItems: Array<GridItemCaptionProps | GridItemImageProps | GridItemVideoProps>
        tags: [
          { tag: string }
        ]
      }]
    }
  }  
}


export const query = graphql`
  query TagQuery {
    allSanityPost {
      nodes {
        title
        slug {
          current
        }
        tags {
          tag
        }
        gridItems {
          ... on SanityGridItem {
            ...gridItemFields
          }
        }
      }
    }
  }
`

export default TagPage
